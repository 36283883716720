<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">平台消息管理</h1>
    </div>
    <div slot="extra">
      <div class="more-info">
      </div>
    </div>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-form-item label="标题">
            <a-input v-model="searchParams.title" @change="search" placeholder="请输入标题"/>
          </a-form-item>
          <a-form-item class="f-r m-r-none">
            <a-button type="primary" @click="search" icon="search">查询</a-button>
            <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
          </a-form-item>
        </a-form>
      </div>
      <a-table bordered
               size="middle"
               :rowKey="record => record.id"
               @change="tableChange"
               :pagination="pagination"
               :columns="columns"
               :dataSource="tableData"
               :loading="tableLoading">
        <template slot="_index" slot-scope="text, record, index">{{index+1}}</template>
        <template slot="ifRead" slot-scope="text, record">
          <a-tag v-if="record.ifRead" color="green">已读</a-tag>
          <a-tag v-else color="red">未读</a-tag>
        </template>
      </a-table>
    </a-card>
  </page-layout>
</template>

<script>
  import entityCRUD from '../../common/mixins/entityCRUD'
  import PageLayout from '@/components/page/PageLayout'
  import SERVICE_URLS from '@/api/service.url'
  import { messageColumns } from './common/common'

  export default {
    name: 'integralList',
    mixins: [entityCRUD],
    components: { PageLayout },
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.platBase.platMessage,
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          userId: null,
          title: ''
        },
        initColumns: messageColumns()
      }
    },
    computed: {
      currentUser () {
        return this.$store.getters.currentUser
      }
    },
    created () {
      this.searchParams.userId = this.currentUser.id
    }
  }
</script>