export const messageColumns = () => {
  return [
    {
      title: '序号',
      width: '80px',
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '标题',
      align: 'left',
      width: '100px',
      dataIndex: 'title'
    },
    {
      title: '内容',
      align: 'left',
      dataIndex: 'content'
    },
    {
      title: '发布时间',
      align: 'center',
      width: '180px',
      dataIndex: 'dateCreated'
    },
    {
      title: '操作类型',
      width: '120px',
      align: 'center',
      dataIndex: 'systemModule'
    },
    {
      title: '状态',
      width: '100px',
      align: 'center',
      dataIndex: 'ifRead',
      scopedSlots: { customRender: 'ifRead' }
    }
  ]
}